import { ApolloProvider } from '@apollo/client'
import AppThemeConfiguration from '@deltek/specification-client/core/Configurations/AppThemeConfiguration'
import { AppHeaderProvider } from '@deltek/specification-client/core/Contexts/AppHeaderContext'
import NavPromptDialogContainer from '@deltek/specification-client/core/Forms/NavPromptDialogContainer'
import React from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter } from 'react-router-dom'
import { client } from './client'

// Have check to prevent errors during build
const hasWindow = typeof window !== 'undefined'

export const PageConfiguration: React.FC = ({ children }) => (
    <>
        <Helmet>
            <meta
                name="viewport"
                content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
            />
            <meta name="theme-color" content="#043258" />
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/icon?family=Material+Icons"
            />
            <link
                href="https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap"
                rel="stylesheet"
            />
        </Helmet>

        <AppThemeConfiguration>
            {hasWindow && (
                <BrowserRouter getUserConfirmation={NavPromptDialogContainer}>
                    <AppHeaderProvider appTitle="Manufacturer">
                        <ApolloProvider client={client}>
                            {children}
                        </ApolloProvider>
                    </AppHeaderProvider>
                </BrowserRouter>
            )}
        </AppThemeConfiguration>
    </>
)
