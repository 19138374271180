import { TypePolicies } from '@apollo/client'
import {
    listingDetailTypePolicy,
    listingMatchTypePolicy,
    listingSearchFieldPolicy,
} from './listing.policies'

/*
 * For Reference
 * https://www.apollographql.com/blog/previewing-the-apollo-client-3-cache-565fadd6a01e/
 */

const typePolicies: TypePolicies = {
    /*
     * ROOT_QUERY contains all the fields/queries
     * Add to fields for Pagination, Data Normalization, etc
     */
    Query: {
        fields: {
            ...listingSearchFieldPolicy,
        },
    },
    /*
     * Add below the types which have an identifier other than id
     * This is the replacement of dataIdFromObject as it will be deprecated
     * in future versions of @apollo/client
     */
    ...listingDetailTypePolicy,
    ...listingMatchTypePolicy,
}

export default typePolicies
