/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

const React = require("react")
const { PageConfiguration }= require('./src/components/common/PageConfiguration')

exports.wrapRootElement = ({ element }) => {
    return (
        <PageConfiguration>
            {element}
        </PageConfiguration>
    )
}