import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import fetch from 'cross-fetch'
import typePolicies from '../../api/graphQL/policies/main.policies'

const publicURL = process.env.GATSBY_PUBLIC_API_ENDPOINT
    ? `${process.env.GATSBY_PUBLIC_API_ENDPOINT}`
    : 'https://publicapiprd01.azurewebsites.net/' // Added fallback in case no public api endpoint available

export const client = new ApolloClient({
    link: createHttpLink({
        uri: publicURL,
        fetch,
    }),
    cache: new InMemoryCache({ typePolicies }),
})
