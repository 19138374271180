module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Specpoint","short_name":"Specpoint","start_url":"/products/all","icons":[{"src":"favicon.ico","sizes":"128x128 64x64 32x32 24x24 16x16","type":"image/x-icon"},{"src":"images/kp192x192logo.png","type":"image/png","sizes":"192x192"},{"src":"images/kp512x512logo.png","type":"image/png","sizes":"512x512"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
